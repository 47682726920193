<template>
  <div class="h-full w-full px-4">
    <portal to="topbar-left">
      <div
        @click="goBack"
        class="relative font-bold"
        key="info-chems-back"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="h-6 w-6">
          <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
            <path d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z" />
          </g>
        </svg>
      </div>
    </portal>

    <h1 class="mb-4 text-3xl font-bold">
      {{ $t("pages.chems") }}
    </h1>

    <div class="relative mx-auto mt-6 w-full rounded-lg bg-white p-4 pr-8 text-sm leading-normal">
      <div class="prose prose-sm max-w-full break-words">
        <vue-markdown>{{ $t("cheminfo.introduction") }}</vue-markdown>
      </div>

      <div class="text-sm font-semibold text-red-600">
        {{ $t("cheminfo.important-note-title") }}
      </div>

      <div class="prose prose-sm max-w-full break-words">
        <vue-markdown>{{ $t("cheminfo.important-note-description") }}</vue-markdown>
      </div>
    </div>

    <div class="mt-4 flex flex-col space-y-4">
      <router-link :to="{ name: 'info-drugcombos' }" class="w-full">
        <list-item class="w-full cursor-pointer">
          <template v-slot:start>
            <icon-drugcombo class="h-5 w-5 fill-current" />
          </template>
          <template v-slot:default>
            <span class="truncate text-sm font-medium">{{ $t(`navigation.drugcombos`) }}</span>
          </template>
          <template v-slot:end>
            <icon-forward class="h-4 w-2 sm:h-6 sm:w-4" />
          </template>
        </list-item>
      </router-link>

      <!-- <pre>{{ chems }}</pre> -->

      <div
        v-for="chem_option in Object.values(chems).sort((a, b) => $t(`chem.${a.key}`).localeCompare($t(`chem.${b.key}`)))"
        :key="`${chem_option.key}_option_to_select`"
        class="spacy-y-4 flex flex-col"
      >
        <list-item @click.native="openPopupFor(chem_option.key)" class="w-full cursor-pointer bg-brand-navblue text-white">
          <template v-slot:default>
            <span class="truncate text-sm font-medium text-white">{{ $t(`chem.${chem_option.key}`) }}</span>
          </template>
          <template v-slot:end>
            <icon-forward class="h-4 w-2 text-white sm:h-6 sm:w-4" />
          </template>
        </list-item>
      </div>
    </div>

    <popup-pane v-if="showPopup" :title="$t(`chem.${selectedChem}`)" @close="closePopup()" :dark="isCheckedIn" key="cheminfo_popup">
      <article class="space-y-10 pb-64">
        <div
          v-if="!!selectedChemData['description']"
          :class="{
            'prose prose-sm max-w-none break-words': true,
            'text-white': isCheckedIn,
          }"
        >
          <vue-markdown>{{ $t(selectedChemData.description) }}</vue-markdown>
        </div>

        <!-- <div class="p-4 shadow">
            <pre>{{selectedChemData.administration}}</pre>
          </div> -->

        <t-button
          v-if="selectedChemData['administration']"
          :variant="{ smallwhiteborder: !viewType, smallborder: !!viewType }"
          @click="toggleViewType"
          >{{ $t("cheminfo.btn--toggle-view-type--" + (viewType ? "indicative" : "detailed")) }}</t-button
        >

        <div class="space-y-6">
          <h2
            class="relative z-20 mb-2 text-lg font-bold"
            v-if="selectedChemData['administration'] || selectedChemData['dosage_description']"
          >
            {{ $t("cheminfo.dosage") }}
          </h2>
          <div v-if="!!selectedChemData['dosage_description']">
            <div
              :class="{
                'prose prose-sm max-w-none break-words': true,
                'text-white': isCheckedIn,
              }"
            >
              <vue-markdown>{{ $t(selectedChemData.dosage_description) }}</vue-markdown>
            </div>
          </div>

          <!-- ADMINISTRATION Indicative dosage -->
          <div v-if="selectedChemData['administration']" class="mb-6 space-y-6">
            <div
              v-for="(administration, chem_key) in selectedChemData.administration"
              :key="`chem_wrapper_dosage_${chem_key}`"
              class="space-y-2 rounded-tl-md rounded-tr-xl border-t border-l-2"
            >
              <h3 class="relative z-10 w-full rounded-tr-xl border-gray-50 bg-gray-300 bg-opacity-50 p-2 font-bold shadow">
                {{ $t("cheminfo.administration_method." + chem_key) }}
              </h3>

              <div v-if="viewType">
                <table class="table w-full overflow-x-auto">
                  <tbody class="divide-y-2">
                    <tr
                      v-for="(dosage_data, dosage_key) in administration.dosage"
                      :key="`administration_method__dosage__${chem_key}_${dosage_key}`"
                    >
                      <th class="truncate px-2 text-left leading-loose">
                        {{ $t("cheminfo.dosage_key." + dosage_key) }}
                      </th>
                      <td class="w-full leading-loose">
                        <div>
                          <span v-if="dosage_data.label_before" v-text="dosage_data.label_before" class="pr-1" />
                          <span v-if="dosage_data.value">
                            {{ dosage_data.value instanceof Array ? dosage_data.value.join(" &ndash; ") : dosage_data.value }}
                          </span>
                          <span v-if="dosage_data.entity" v-text="dosage_data.entity" />
                          <span v-if="dosage_data.label_after" v-text="dosage_data.label_after" class="pl-1" />
                        </div>
                        <div v-if="dosage_data.warning" class="text-sm font-semibold text-red-600">
                          {{ $t(dosage_data.warning) }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <div class="relative space-y-2 px-6 pt-8">
                  <div class="flex h-4 flex-row justify-around overflow-hidden rounded-lg bg-gray-50 shadow-lg">
                    <div
                      v-for="(chem_color, chem_key) in {
                        light: '#34D399',
                        common: '#F59E0B',
                        strong: '#EF4444',
                      }"
                      :key="`administration_method__dosage_${selectedChem}_${chem_key}`"
                      class="relative h-full truncate shadow-inner"
                      :style="{
                        'background-color': chem_color,
                        width:
                          ((Math.max(...administration.dosage[chem_key]['value']) - Math.min(...administration.dosage[chem_key]['value'])) /
                            (Math.max(...administration.dosage['strong']['value']) -
                              Math.min(...administration.dosage['light']['value']))) *
                            100 +
                          `%`,
                      }"
                      :title="`${chem_key} = ${administration.dosage[chem_key]['value']}`"
                    ></div>
                  </div>

                  <div class="relative flex select-none flex-row justify-around">
                    <div
                      v-for="chem_key in ['light', 'common', 'strong']"
                      :key="`administration_method__dosage_label_${selectedChem}_${chem_key}`"
                      class="relative h-6 text-center text-xs"
                      :style="{
                        width:
                          ((Math.max(...administration.dosage[chem_key]['value']) - Math.min(...administration.dosage[chem_key]['value'])) /
                            (Math.max(...administration.dosage['strong']['value']) -
                              Math.min(...administration.dosage['light']['value']))) *
                            100 +
                          `%`,
                      }"
                    >
                      <div class="absolute -left-4 -top-12 truncate" style="font-size: 10px">
                        {{ Math.min(...administration.dosage[chem_key]["value"]) + administration.dosage[chem_key]["entity"] }}
                      </div>
                      <div class="absolute -right-4 -top-12 truncate" v-if="chem_key == 'strong'" style="font-size: 10px">
                        {{ Math.max(...administration.dosage[chem_key]["value"]) + administration.dosage[chem_key]["entity"] }}
                      </div>

                      <span class="truncate">{{ $t("cheminfo.dosage_key." + chem_key) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="space-y-6">
          <h2
            class="relative z-20 mb-2 text-lg font-bold"
            v-if="selectedChemData['administration'] || selectedChemData['duration_description']"
          >
            {{ $t("cheminfo.duration") }}
          </h2>
          <div v-if="!!selectedChemData['duration_description']">
            <div
              :class="{
                'prose prose-sm max-w-none break-words': true,
                'text-white': isCheckedIn,
              }"
            >
              <vue-markdown>{{ $t(selectedChemData.duration_description) }}</vue-markdown>
            </div>
          </div>

          <!-- ADMINISTRATION Indicative duration -->
          <div v-if="selectedChemData['administration']" class="mb-6 space-y-6">
            <div
              v-for="(administration, chem_key) in selectedChemData.administration"
              :key="`chem_wrapper_duration_${chem_key}`"
              class="space-y-2 rounded-tr-xl rounded-tl-md border-t border-l-2"
            >
              <h3
                class="relative z-10 w-full rounded-tr-xl border-gray-50 bg-gray-300 bg-opacity-30 p-2 font-bold shadow"
                :class="{ 'bg-gray-50': isCheckedIn }"
              >
                {{ $t("cheminfo.administration_method." + chem_key) }}
              </h3>

              <table class="table w-full overflow-x-auto">
                <tbody class="divide-y-2">
                  <tr
                    v-for="(duration_data, duration_key) in administration.duration"
                    :key="`administration_method__duration__${chem_key}_${duration_key}`"
                  >
                    <th class="truncate px-2 text-left leading-loose">
                      {{ $t("cheminfo.duration_key." + duration_key) }}
                    </th>
                    <td class="w-full leading-loose">
                      <div>
                        <span v-if="duration_data.label_before" v-text="duration_data.label_before" class="pr-1" />

                        <span v-if="duration_data.value instanceof Array">
                          <span v-for="(value, value_key) in duration_data.value" :key="`chem_wrapper_duration_${chem_key}__${value_key}`">
                            <span v-if="value_key"> &ndash; </span>
                            <span>{{ $moment.duration(value, "seconds").humanize() }}</span>
                          </span>
                        </span>

                        <span v-else-if="!isNaN(duration_data.value)">
                          {{ $moment.duration(duration_data.value, "seconds").humanize() }}
                        </span>

                        <span v-else>?</span>
                        <!-- <span v-if="duration_data.entity" v-text="$moment.duration(duration_data.entity).humanize()" class="pl-1" /> -->
                        <!-- <span v-if="duration_data.entity" v-text="duration_data.entity" class="pl-1" /> -->
                        <!-- <span v-if="duration_data.label_after" v-text="duration_data.label_after" class="pl-1" /> -->
                      </div>
                      <div v-if="duration_data.warning" class="text-sm font-semibold text-red-600">
                        {{ $t(duration_data.warning) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          v-if="
            !!selectedChemData['effects_introduction'] || (!!selectedChemData['effects'] && selectedChemData['effects'] instanceof Object)
          "
        >
          <h2 class="relative z-20 mb-2 text-lg font-bold">
            {{ $t("cheminfo.effects") }}
          </h2>
          <div
            v-if="!!selectedChemData['effects_introduction']"
            :class="{
              'prose prose-sm max-w-none break-words': true,
              'text-white': isCheckedIn,
            }"
          >
            <vue-markdown>{{ $t(selectedChemData.effects_introduction) }}</vue-markdown>
          </div>
          <dl v-if="!!selectedChemData['effects'] && selectedChemData['effects'] instanceof Object" class="space-y-2 divide-y leading-snug">
            <div v-for="(effect, index) in selectedChemData.effects" :key="`effect_${index}`" class="pt-2">
              <dt class="font-semibold">
                {{ $t(`cheminfo.chem_effect_titles.${effect}`) }}
              </dt>
              <dd
                :class="{
                  'prose prose-sm max-w-none break-words': true,
                  'text-white': isCheckedIn,
                }"
              >
                <vue-markdown>{{ $t(`cheminfo.chem_info.${selectedChemData.key}.effects.${effect}`) }}</vue-markdown>
              </dd>
            </div>
          </dl>
        </div>

        <div v-if="!!selectedChemData['avoid_interactions']">
          <h2 class="relative z-20 mb-2 text-lg font-bold">
            {{ $t("cheminfo.avoid_interactions") }}
          </h2>
          <div
            :class="{
              'prose prose-sm max-w-none break-words': true,
              'text-white': isCheckedIn,
            }"
          >
            <vue-markdown>{{ $t(selectedChemData.avoid_interactions) }}</vue-markdown>
          </div>
        </div>

        <div v-if="selectedChemData.danger_risk">
          <p class="relative z-20 text-base font-semibold text-red-600">
            {{ $t("cheminfo.chem_risks." + selectedChemData.danger_risk + ".title") }}
          </p>
          <div
            :class="{
              'prose prose-sm max-w-none break-words': true,
              'text-white': isCheckedIn,
            }"
          >
            <vue-markdown>{{ $t("cheminfo.chem_risks." + selectedChemData.danger_risk + ".description") }}</vue-markdown>
          </div>
        </div>

        <!-- LINKS -->
        <ul
          v-if="!!selectedChemData['links'] && selectedChemData['links'] instanceof Array && selectedChemData['links'].length"
          class="mt-6"
        >
          <h5 class="text-xs font-semibold underline">{{ $t("cheminfo.links-title") }}:</h5>
          <li
            v-for="(link, link_index) in selectedChemData['links']"
            :key="`cheminfo_links${link_index}`"
            class="flex flex-row items-baseline justify-start space-x-1 space-y-1 leading-relaxed"
          >
            <svg class="h-3 w-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <a :href="link.url || '#'" target="_blank" v-text="link.title || '?'" />
          </li>
        </ul>
      </article>
    </popup-pane>
  </div>
</template>

<script>
import PopupPane from "components/PopupPane";
// import { dosage_keys, administration_methods, duration_keys, chems } from "../../utils/chems";
import Repository from "repositories/RepositoryFactory";
const ChemRepository = Repository.get("chem");
import ListItem from "components/ListItem";
import IconForward from "@/assets/icons/forward.svg";
import VueMarkdown from "vue-markdown";
import { mapGetters } from "vuex";
import IconDrugcombo from "@/assets/icons/drug-pills.svg";

export default {
  name: "info-cheminfo",
  components: {
    PopupPane,
    ListItem,
    IconForward,
    VueMarkdown,
    IconDrugcombo,
  },
  data: () => ({
    showPopup: false,
    selectedChem: undefined,
    // dosage_keys, administration_methods, duration_keys, chems,
    viewType: false,
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  }),
  methods: {
    openPopupFor(key) {
      this.selectedChem = key;
      this.showPopup = true;
    },
    closePopup() {
      this.selectedChem = undefined;
      this.showPopup = false;
    },
    toggleViewType() {
      this.viewType = !this.viewType;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["isCheckedIn"]),

    chems() {
      return ChemRepository.chems() || [];
    },
    selectedChemData() {
      if (this.selectedChem) {
        console.log(ChemRepository.chem(this.selectedChem));
        return ChemRepository.chem(this.selectedChem);
      }
      return null;
    },
  },

  watch: {
    // whenever question changes, this function will run
    showPopup: function (newState) {
      if (newState && this.$gtm && this.$gtm.enabled()) {
        this.$gtm.trackEvent({
          category: "ChemInfo",
          action: "click",
          label: "Opened chem for more info",
          value: this.selectedChem,
        });
      }
    },
  },
  metaInfo() {
    return {
      title: `${this.$i18n.t("pages.chems")}`,
    };
  },
};
</script>
